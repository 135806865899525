'use client';

import { useTheme } from '@royalaholddelhaize/ah-next-core/src/theme/client/use-theme';
import { UIThemePreferenceQueryParam } from '@royalaholddelhaize/ah-next-core/src/theme/constants';
import { createTestHook } from '@royalaholddelhaize/ah-test-hooks';
import { ContrastHigh24Icon } from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah';
import { Stack } from '@royalaholddelhaize/design-system-pantry-web/components/layout/stack/stack';
import { Switch } from '@royalaholddelhaize/design-system-pantry-web/components/switch/switch';
import { Typography } from '@royalaholddelhaize/design-system-pantry-web/foundation/typography/typography';
import type { FC } from 'react';
import { createTranslate } from '../../../../../../i18n/translation';
import { headerTranslations } from '../../../../../i18n/translation';
import type { HeaderSupportedLocales } from '../../../../../interfaces/supported-locales';
import css from './accessibility-switch.module.scss';

interface AccessibilitySwitchProps {
    locale: HeaderSupportedLocales;
}

export const AccessibilitySwitch: FC<AccessibilitySwitchProps> = ({
    locale,
}) => {
    const t = createTranslate(locale, headerTranslations);
    const { theme, toggleTheme } = useTheme();

    return (
        <Stack
            className={css.root}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            {...createTestHook('switch-a11y')}
        >
            <Stack
                className={css.content}
                direction="row"
                alignItems="center"
                justifyContent="flex-start"
            >
                <ContrastHigh24Icon
                    className={css.icon}
                    size={24}
                    aria-hidden
                />
                <div>
                    <Typography variant="body-strong">
                        {t('accessibility-switch.title')}
                    </Typography>
                    <Typography className={css.subtext}>
                        {t('accessibility-switch.description')}
                    </Typography>
                </div>
            </Stack>
            <Switch
                id="accessibility-switch"
                selected={
                    theme === UIThemePreferenceQueryParam.LIGHT_HIGH_CONTRAST
                }
                onClick={toggleTheme}
                aria-label={
                    theme === UIThemePreferenceQueryParam.LIGHT_HIGH_CONTRAST
                        ? t('accessibility-switch.aria-label-active')
                        : t('accessibility-switch.aria-label')
                }
            />
        </Stack>
    );
};
