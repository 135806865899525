import { UIThemePreferenceQueryParam } from '../constants';

// Validate the theme query param
export const validateTheme = (
    theme: string,
): theme is UIThemePreferenceQueryParam => {
    return Object.values(UIThemePreferenceQueryParam).includes(
        theme as UIThemePreferenceQueryParam,
    );
};
