'use client';

import {
    AH_UI_NAVIGATION_TESTHOOKS,
    createTestHook,
} from '@royalaholddelhaize/ah-test-hooks';
import { Checkmark16Icon } from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah/checkmark-16';
import {
    LinkAnchor,
    LinkText,
} from '@royalaholddelhaize/design-system-pantry-web/components/button/link-button/link-button';
import classnames from 'clsx';
import type { UspGroup } from '../../../graphql/top-bar/top-bar.hook';
import { createTranslate } from '../../../i18n/translation';
import { useMember } from '../../contexts/member';
import { HeaderFeatureOptionsName } from '../../enums/feature-options';
import { headerTranslations } from '../../i18n/translation';
import type { HeaderFeatureOptions } from '../../interfaces/feature-options';
import type { HeaderSupportedLocales } from '../../interfaces/supported-locales';
import { TopBarLink } from './components/link/top-bar-link';
import { linksAllUsers, linksB2C } from './top-bar.constants';
import css from './top-bar.module.scss';

type TopBarProps = {
    uspGroup: UspGroup;
    locale: HeaderSupportedLocales;
    featureOptions?: HeaderFeatureOptions;
};

export const TopBar = ({
    uspGroup: { uspList, themeColor, link, title },
    locale,
    featureOptions = {},
}: TopBarProps) => {
    const {
        member: { isB2B },
    } = useMember();

    const t = createTranslate(locale, headerTranslations);

    const links = isB2B ? linksAllUsers : linksB2C.concat(linksAllUsers);

    const abFilteredLinks = links.filter(link => {
        if (
            link.label === 'menu.accessibility' &&
            !featureOptions[HeaderFeatureOptionsName.AccessibilitySwitch]
        ) {
            return false;
        }

        return true;
    });

    return (
        <div className={css.root}>
            <div className={css.bar}>
                <menu
                    className={classnames(
                        css.list,
                        css[themeColor as keyof typeof css],
                    )}
                    {...createTestHook(
                        AH_UI_NAVIGATION_TESTHOOKS.HEADER.uspsList,
                    )}
                >
                    <li key={title} className={css.item}>
                        <LinkAnchor
                            title={title}
                            aria-label={t('menu.premium')}
                            className={css.title}
                            href={link}
                        >
                            <LinkText
                                level="primary"
                                size="small"
                                {...createTestHook(
                                    AH_UI_NAVIGATION_TESTHOOKS.HEADER.uspsTitle,
                                )}
                            >
                                {title}
                            </LinkText>
                        </LinkAnchor>
                    </li>
                    {uspList.map(({ link, name }) => (
                        <li key={name} className={css.item}>
                            <LinkAnchor
                                className={css.link}
                                title={name}
                                href={link}
                            >
                                <Checkmark16Icon
                                    className={css.icon}
                                    size={12}
                                />
                                <LinkText
                                    className={css.text}
                                    level="secondary"
                                    size="small"
                                >
                                    {name}
                                </LinkText>
                            </LinkAnchor>
                        </li>
                    ))}
                </menu>
                <menu
                    className={css.links}
                    {...createTestHook(
                        AH_UI_NAVIGATION_TESTHOOKS.HEADER.topBarLinks,
                    )}
                >
                    {abFilteredLinks.map(link => {
                        if (link.type === 'button') {
                            const { component: Component } = link;

                            return (
                                <li key={link.label} className={css.item}>
                                    <Component
                                        label={link.label}
                                        locale={locale}
                                    />
                                </li>
                            );
                        }

                        return (
                            <li key={link.href} className={css.item}>
                                <TopBarLink link={link} locale={locale} />
                            </li>
                        );
                    })}
                </menu>
            </div>
        </div>
    );
};
