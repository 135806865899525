'use client';

import { setCookie } from '@royalaholddelhaize/ah-web-core';
import {
    AH_THEME_PREFERENCE_COOKIE_NAME,
    type UIThemePreferenceQueryParam,
    pantryThemeMap,
} from '../constants';
import { validateTheme } from '../helpers/validate-theme';

export const setClientSideTheme = (theme: UIThemePreferenceQueryParam) => {
    if (!validateTheme(theme)) {
        return;
    }

    try {
        setCookie({
            name: AH_THEME_PREFERENCE_COOKIE_NAME,
            value: theme,
            expires: new Date(Date.now() + 31536000000),
            path: '/',
        });
        document.documentElement.dataset.theme = pantryThemeMap[theme];
    } catch (error) {
        console.error('Error setting theme preference', error);
    }
};
