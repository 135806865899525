import {
    AH_UI_NAVIGATION_TESTHOOKS,
    createTestHook,
} from '@royalaholddelhaize/ah-test-hooks';
import type { FC } from 'react';
import { useNavigationHeader } from '../../../../hooks/navigation-header.hook';
import type { HeaderFeatureOptions } from '../../../../interfaces/feature-options';
import type { HeaderSupportedDomains } from '../../../../interfaces/supported-domains';
import type { HeaderSupportedLocales } from '../../../../interfaces/supported-locales';
import { MenuItemComponentWrapper } from '../../../menu-item-component/menu-item-component-wrapper';
import { AccessibilitySwitch } from '../../../top-bar/components/button/components/accessibility-switch';
import css from '../nav-panel-pane.module.scss';

type NavPanelProfileProps = {
    featureOptions: HeaderFeatureOptions;
    domain: HeaderSupportedDomains;
    locale: HeaderSupportedLocales;
};

export const NavPanelAccessibility: FC<NavPanelProfileProps> = ({
    domain,
    locale,
}) => {
    const { menuItems } = useNavigationHeader(domain);
    const accessibilityItem = menuItems.accessibility[0];
    return (
        <div
            className={css.pane}
            {...createTestHook(AH_UI_NAVIGATION_TESTHOOKS.HEADER.accessibility)}
        >
            <menu className={css.menuList}>
                <MenuItemComponentWrapper
                    className="compactMenuItem"
                    item={accessibilityItem}
                >
                    <AccessibilitySwitch locale={locale} />
                </MenuItemComponentWrapper>
            </menu>
        </div>
    );
};
