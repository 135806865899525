'use client';

import { createTestHook } from '@royalaholddelhaize/ah-test-hooks';
import classNames from 'clsx';
import type { PropsWithChildren } from 'react';
import { MenuItemId } from '../../enums/menu-item-id';
import type { MainMenuItemProps } from './menu-item-component';
import css from './menu-item-component.module.scss';

export const MenuItemComponentWrapper = ({
    className,
    greyedOut = false,
    isMenuHeader = false,
    foldoutVariant,
    id,
    children,
    item,
}: PropsWithChildren &
    Pick<
        MainMenuItemProps,
        | 'className'
        | 'greyedOut'
        | 'isMenuHeader'
        | 'foldoutVariant'
        | 'id'
        | 'item'
    >) => {
    return (
        <li
            className={classNames(
                css[className as keyof typeof css],
                greyedOut && css.menuItemGreyedOut,
                foldoutVariant && css.foldoutVariant,
                isMenuHeader && css.menuItemHeader,
                item.id === MenuItemId.RECIPES && css.secondaryMenuItemRecipes,
            )}
            id={id}
            {...createTestHook(item.testHook)}
        >
            {children}
        </li>
    );
};
