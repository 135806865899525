import {
    LinkAnchor,
    LinkText,
} from '@royalaholddelhaize/design-system-pantry-web/components/button/link-button/link-button';
import { createTranslate } from '../../../../../i18n/translation';
import {
    type HeaderTranslationKey,
    headerTranslations,
} from '../../../../i18n/translation';
import type { HeaderSupportedLocales } from '../../../../interfaces/supported-locales';
import type { LinkItem } from '../../top-bar.constants';
import css from './top-bar-link.module.scss';

export const TopBarLink: React.FC<{
    link: LinkItem;
    locale: HeaderSupportedLocales;
}> = ({ link, locale }) => {
    const t = createTranslate(locale, headerTranslations);
    return (
        <LinkAnchor
            className={css.link}
            title={t(link.label as HeaderTranslationKey)}
            href={link.href}
        >
            <LinkText className={css.text} level="secondary" size="small">
                {t(link.label as HeaderTranslationKey)}
            </LinkText>
        </LinkAnchor>
    );
};
