'use client';

import { useEffect, useState } from 'react';
import { UIThemePreferenceQueryParam } from '../constants';
import { getClientSideTheme } from './get-client-side-theme';
import { setClientSideTheme } from './set-client-side-theme';

export const useTheme = () => {
    // theme (from a cookie)
    const clientTheme = getClientSideTheme();
    // theme state to be used in the app
    const [theme, setTheme] =
        useState<UIThemePreferenceQueryParam>(clientTheme);

    // update the theme cookie and the theme state
    const setClientTheme = (newTheme: UIThemePreferenceQueryParam) => {
        setTheme(newTheme);
        setClientSideTheme(newTheme);
    };

    // toggle the theme
    const toggleTheme = () => {
        if (theme === UIThemePreferenceQueryParam.LIGHT_HIGH_CONTRAST) {
            setClientTheme(UIThemePreferenceQueryParam.LIGHT);
        } else {
            setClientTheme(UIThemePreferenceQueryParam.LIGHT_HIGH_CONTRAST);
        }
    };

    // observe changes in the theme attribute of the <html> element
    // this allows use of this hook to be syncronized with the theme attribute
    useEffect(() => {
        const observer = new MutationObserver(mutationsList => {
            for (const mutation of mutationsList) {
                if (mutation.type === 'attributes') {
                    const newTheme = getClientSideTheme();
                    setTheme(newTheme);
                }
            }
        });

        observer.observe(document.documentElement, {
            attributes: true,
        });

        return () => {
            observer.disconnect();
        };
    }, []);

    return {
        theme,
        toggleTheme,
    };
};
