export const AH_THEME_PREFERENCE_COOKIE_NAME = 'ah-ui-theme'; // ah-ui-theme=LIGHT
export const AH_THEME_PREFERENCE_QUERY_PARAM_NAME = 'theme'; // ?theme=LIGHT

// Enum of supported query params for UI theme
// Appietime passes the user selected theme as a query param
export enum UIThemePreferenceQueryParam {
    LIGHT = 'LIGHT',
    DARK = 'DARK',
    LIGHT_HIGH_CONTRAST = 'LIGHT_HIGH_CONTRAST',
}

// Enum of possible pantry themes
// Should ideally be imported from pantry
export enum PantryTheme {
    LIGHT = 'light',
    DARK = 'dark',
    LIGHT_HIGH_CONTRAST = 'lightHighContrast',
}

// Map of query param to supported pantry theme values
export const pantryThemeMap = {
    [UIThemePreferenceQueryParam.LIGHT]: PantryTheme.LIGHT,
    [UIThemePreferenceQueryParam.DARK]: PantryTheme.LIGHT, // Dark is not (yet) supported
    [UIThemePreferenceQueryParam.LIGHT_HIGH_CONTRAST]:
        PantryTheme.LIGHT_HIGH_CONTRAST,
};
