import { getCookie } from '@royalaholddelhaize/ah-web-core';
import {
    AH_THEME_PREFERENCE_COOKIE_NAME,
    UIThemePreferenceQueryParam,
} from '../constants';
import { validateTheme } from '../helpers/validate-theme';

export const getClientSideTheme = (): UIThemePreferenceQueryParam => {
    const theme = getCookie(AH_THEME_PREFERENCE_COOKIE_NAME);

    if (validateTheme(theme as UIThemePreferenceQueryParam)) {
        return theme as UIThemePreferenceQueryParam;
    }

    return UIThemePreferenceQueryParam.LIGHT;
};
