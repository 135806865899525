import { PATH } from '../../constants/path';
import type { HeaderTranslationKey } from '../../i18n/translation';
import type { HeaderSupportedLocales } from '../../interfaces/supported-locales';
import { TopBarButton } from './components/button/accessibility-button';

export interface LinkItem {
    type: 'link';
    label: HeaderTranslationKey;
    href: string;
}

export interface LinkButtonItem {
    type: 'button';
    label: HeaderTranslationKey;
    component: React.FC<{
        locale: HeaderSupportedLocales;
        label: HeaderTranslationKey;
    }>;
}

export const linksB2C: (LinkItem | LinkButtonItem)[] = [
    {
        type: 'link',
        label: 'menu.promotions',
        href: PATH.campaigns,
    },
];

export const linksAllUsers: (LinkItem | LinkButtonItem)[] = [
    {
        type: 'link',
        label: 'menu.shops',
        href: PATH.shops,
    },
    {
        type: 'link',
        label: 'menu.business',
        href: PATH.business,
    },
    {
        type: 'link',
        label: 'menu.customerService',
        href: PATH.customerService,
    },
    {
        type: 'button',
        label: 'menu.accessibility',
        component: TopBarButton,
    },
];
