'use client';

import { Accessibility24Icon } from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah';
import {
    LinkButton,
    LinkText,
} from '@royalaholddelhaize/design-system-pantry-web/components/button/link-button/link-button';
import {
    Popover,
    PopoverContent,
    usePopover,
} from '@royalaholddelhaize/design-system-pantry-web/components/experimental/popover';
import { createTranslate } from '../../../../../i18n/translation';
import {
    type HeaderTranslationKey,
    headerTranslations,
} from '../../../../i18n/translation';
import type { HeaderSupportedLocales } from '../../../../interfaces/supported-locales';
import css from './accessibility-button.module.scss';
import { AccessibilitySwitch } from './components/accessibility-switch';

export const TopBarButton: React.FC<{
    label: HeaderTranslationKey;
    locale: HeaderSupportedLocales;
}> = ({ label, locale }) => {
    const t = createTranslate(locale, headerTranslations);

    const { isOpen, popoverRef, openPopover, closePopover } = usePopover();

    const togglePopover = () => {
        if (isOpen) {
            closePopover();
        } else {
            openPopover();
        }
    };

    return (
        <Popover ref={popoverRef}>
            <LinkButton
                className={css.button}
                title={t(label as HeaderTranslationKey)}
                onClick={togglePopover}
            >
                <Accessibility24Icon size={16} />
                <LinkText className={css.text} level="secondary" size="small">
                    {t(label as HeaderTranslationKey)}
                </LinkText>
            </LinkButton>
            <PopoverContent open={isOpen} className={css.menu}>
                <AccessibilitySwitch locale={locale} />
            </PopoverContent>
        </Popover>
    );
};
