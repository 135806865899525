export enum MenuItemId {
    PRODUCTS = 'PRODUCTS',
    BONUS = 'BONUS',
    RECIPES = 'RECIPES',
    TICKET_SHOP = 'TICKET_SHOP',
    CAKE_BAKERS = 'CAKE_BAKERS',
    ABOUT = 'ABOUT',
    SAVINGS_PROMOTIONS = 'SAVINGS_PROMOTIONS',
    SHOPS = 'SHOPS',
    B2B = 'B2B',
    CUSTOMER_SERVICE = 'CUSTOMER_SERVICE',
    PROFILE = 'PROFILE',
    PREVIOUSLY_BOUGHT = 'PREVIOUSLY_BOUGHT',
    ORDERS = 'ORDERS',
    DELIVERY_BUNDLE = 'DELIVERY_BUNDLE',
    DELIVERY_BUNDLE_BUSINESS = 'DELIVERY_BUNDLE_BUSINESS',
    ORDER_ONLINE = 'ORDER_ONLINE',
    JOBS = 'JOBS',
    FAVORITES = 'FAVORITES',
    FOLDER = 'FOLDER',
    LOGIN = 'LOGIN',
    LOGOUT = 'LOGOUT',
    PREMIUM = 'PREMIUM',
    ACCESSIBILITY = 'ACCESSIBILITY',
}
